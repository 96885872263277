import React, {useEffect, useState, useRef} from 'react'
import Header from './Header'
import Fundraiser from './Fundraiser'
import Footer from './Footer'
import "../css/SingleCategory.css"
import { useParams } from 'react-router-dom'
import useFetch from './UseFetch'
import { domain } from './Domain'
import Loader from './Loader'
import DynamicMetaTags from './DynamicMetaTags'


function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
}
const SingleCategory = () => {
    const [ spinner, setSpinner ] = useState(true);
    const { name } = useParams();
    const {data, loading, error} = useFetch(`${domain}/fundraisers?category=${name}`);
    const {data:categories} = useFetch(`${domain}/fundraisers-count`);

    const divRef = useRef(null);

    // Function to scroll to the start of the div
    const scrollToStart = () => {
        // Use the current property of the ref to access the DOM element
        if (divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    scrollToStart()

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500)
    }, []);
    return (
        <>
            {spinner ? <Loader /> : 
                <div ref={divRef}>
                    <div>
                        <DynamicMetaTags 
                            pageTitle = {'Categories | ' + capitalizeFirstLetter(name)} 
                            metaDescription = {categories && categories.filter(request => request.name === name)[0] && categories.filter(request => request.name === name)[0].description} 
                            imageUrl={categories && categories.filter(request => request.name === name)[0] && domain+categories.filter(request => request.name === name)[0].image} 
                            pageUrl = {domain + '/' +name}
                        />
                        <Header />
                        <div className='Heading'>
                            <h1 className='fs-4 pt-2 pb-2 fw-bold'>{capitalizeFirstLetter(name)} Fundraising Requests</h1>
                        </div>
                        <div className='card-elements'>
                            {error && <p>{error}</p>}
                            {loading && <p>Loading data ....</p>}
                            {data &&
                                (
                                data.map(data => {
                                    return(
                                    <Fundraiser key={data.id} data={data}/>
                                    )
                                })
                                )
                            }
                        </div>
                        <Footer />
                    </div>
                </div>
            }
        </>
    )
}

export default SingleCategory