import React from 'react'
import { Link } from 'react-router-dom';
import "../css/Share.css"
import { domain } from './Domain';
import truncateString from './TruncateString';
const actualDomain = window.location.hostname

const Share = ({data}) => {
    function copyToClipboard() {
        var copyText = document.getElementById("linkToCopy").value;
        const elem = document.createElement('textarea');
        elem.value = copyText;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
        alert("Copied the text: " + copyText);
    }

    function handleShare(type){
        if(type === 'facebook'){
            window.open('https://www.facebook.com/sharer/sharer.php?u=example.org', 'Whatsapp Chat Window', 'width=500,height=300')
        }
        else if(type ==='twitter'){
            window.open('https://twitter.com/intent/tweet?text=Greetings from '+data.benefitiary+ '\n The person stated above is requesting for your assistance. Brief description is. \n'+truncateString(data.details, 80)+'\n Kindly visit the link '+domain+'/fundraiser/'+data.id+' for more information', 'Twitter Share Window', 'width=500,height=300')
        }
        else if(type === 'whatsapp'){
            window.open('https://wa.me/?text=*Greetings from \n'+data.benefitiary+'*%0aI would really appreciate it if you could share or donate towards this Campaign.%0a %0a*Description*%0a'+truncateString(data.details,100)+'%0a %0aTo know more about this request, kindly visit the link %0ahttps://'+actualDomain+'/fundraiser/'+data.id+'%0a %0a*Forward* this message to your *contacts* to help this *campaign reach its target!*', 'Whatsapp Chat Window', 'width=500 height=300')
        }
        else{
            window.location='mailto:?subject=Request For Donation towards' +data.benefitiary+ '&body='+truncateString(data.details,200)+': Kindly Visit https://'+actualDomain+'/fundraiser/'+data.id+' for more information'
        }
    }
    return (
        <div className="share bg-white">
            <p>Fundraisers shared normally yield better responses</p>
            <hr />
            <div className="shareIcons">
                <Link onClick={() => handleShare('facebook')}><i className="i fab fa-facebook-square text-primary"></i></Link>
                <Link onClick={() => handleShare('twitter')}><i className="i fab fa-twitter text-info"></i></Link>
                <Link onClick={() => handleShare('whatsapp')}><i className="i fab fa-whatsapp text-success"></i></Link>
                <Link onClick={() => handleShare('email')}><i className="i fa fa-envelope text-danger"></i></Link>
            </div>
            <div className="copy">
                <div>
                    <input type="text" className='form-control' value={window.location} id="linkToCopy" readonly/>
                </div>
                <div>
                    <button className='btn btn-success' onClick={copyToClipboard}>Copy Link</button>
                </div>
            </div>
        </div>
    )
}

export default Share