import React, { useState, useEffect } from 'react'
import "../css/UserInfo.css"
import useFetch from './UseFetch'
import { domain } from './Domain'
import { useLoginContext } from './LoginContext';

const UserInfo = () => {
    const auth = useLoginContext()
    const [register, setRegister] = useState(false);
    const {data, loading, error, setData} = useFetch(`${domain}/accounts/profile`);

    const loadPP = () => {
        console.log("changing the picture!")
    }
    const selectPic = () => {
        console.log("selecting a pciture!")
    }

    const handleUserUpdate = (event) => {
        event.preventDefault()
        const formData = new FormData(event.target);
        let headers = {}
        if(auth.userInfo.token !== null){
            headers.Authorization = `Token ${auth.userInfo.token}`
        }
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData,
            headers
        };
        fetch(`${domain}/accounts/profile`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(data =>{
            console.log(data)
            alert(JSON.stringify(data))
        })
        .catch(err =>{
            console.log(err.message)
        })
    }

    const handleChange = (name, e) => {
        const value = e.target.value
        if(name === "first_name"){
            data.first_name = value;
        }
        if(name === "last_name"){
            data.last_name = value;
        }
        if(name === "username"){
            data.username = value;
        }
        if(name === "email"){
            data.email = value;
        }
        if(name === "contact"){
            data.contact = value;
        }
        if(name === "address"){
            data.address = value;
        }
        if(name === "gender"){
            data.gender = value;
        }
        if(name === "DOB"){
            data.DOB = value;
        }
        
        setData({...data})
    }

    const handleSelectPic = () => {
        const pp = document.getElementById("pInput");
        pp.click();
    }

    const handleLoadPP = () => {
        const event = document.getElementById("pInput");
        const selectedFile = event.files[0];
        const canvas = document.getElementById("canvas");
        //canvas.title = selectedFile.name;
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            canvas.style.backgroundImage = `url(${URL.createObjectURL(selectedFile)})`;
            canvas.style.backgroundColor = "grey";
            canvas.style.backgroundSize = "contain";
        }else{
            alert("please select an Image")
        }
        
    }
    return (
        <div className='registration'>
            <p>We are delighted to have you as a part of our community! Your profile is your digital identity here, and it's the place where you can tell the world a bit more about yourself. </p>
            <p>Kindly use actual profile Information to ease fundraising, <br />Thank you</p>
            <form onSubmit={handleUserUpdate}>
                <div className="userInfo">
                    <div className="div">
                        <label>Registered Name</label>
                        <div>
                            <input type="text" name="first_name" value={data && data.first_name} onChange={(e) => handleChange("first_name", e)}  placeholder="First Name" required/>
                            <input type="text" name="last_name" value={data && data.last_name} onChange={(e) => handleChange("last_name", e)} placeholder="Last Name" required/>
                        </div>
                        <label>Username</label>
                        <input type="text" readOnly name="username" value={data && data.username} onChange={(e) => handleChange("username", e)} placeholder="System username" required/>
                        <label>Email</label>
                        <input type="email" name="email" value={data && data.email} onChange={(e) => handleChange("email", e)} placeholder="Email Account" required/>
                    </div>
                    <div className="div">
                        <div>
                            <div className='d-block'>
                                <label>Phone number</label>
                                <input type="text" name="contact" value={data && data.contact} onChange={(e) => handleChange("contact", e)} placeholder="Phone number" required/>
                            </div>
                            <div className='d-block'>
                                <label>Gender</label>
                                <select name="gender" onChange={(e) => handleChange("gender", e)} required>
                                    {data && data.gender === 'Male' && 
                                        <>
                                            <option selected>Male</option>
                                            <option>Female</option>
                                        </>
                                    }
                                    {data && data.gender === 'Female' && 
                                        <>
                                            <option>Male</option>
                                            <option selected>Female</option>
                                        </>
                                    }
                                </select>
                            </div>
                        </div>
                        <label>Physical Address</label>
                        <input type="text" name="address" value={data && data.address} onChange={(e) => handleChange("address", e)} required placeholder="Physical address e.g Makerere-Kavule"/>
                        <label >Date of Birth </label>
                        <input type="date" value={data && data.DOB} required name="DOB" onChange={(e) => handleChange("DOB", e)}/>
                    </div>
                    <div className="div">
                        <label for="profile picture">Profile Picture</label>
                        <div className='d-flex align-items-center justify-content-start'>
                            <canvas id="canvas" className='w-50'></canvas>
                            <span className="ms-4 mb-2 btns" onClick={handleSelectPic}>Select Profile Picture</span>
                        </div>
                        <input type="file" id="pInput" className="d-none" accept="*/image" onChange={handleLoadPP} name="profilepic"/>
                        {!register && <button className='btns'>Update Registration Details</button> }
                        {register && <button className='btns bg-success' disabled>Updating Registration Details, please wait ...</button> }
                    </div> 
                </div>
            </form>
        </div>
    )
}

export default UserInfo