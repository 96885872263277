import React, { useState, useRef, useEffect} from 'react';
import logo from '../logo.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { domain } from './Domain';
import "../css/Login.css"
import Loader from './Loader';
import { useLoginContext } from './LoginContext';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const {isLoggedIn, setIsLoggedIn, userInfo, setUserInfo} = useLoginContext();
  const [login, setLogin] = useState(false)
  const errors = useRef()
  const [ spinner, setSpinner ] = useState(true);

  useEffect(() => {
    setTimeout(() => setSpinner(false), 1000)
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    setLogin(true)
    const formData = new FormData();
    const requestOptions = {
        method: 'POST',
        credentials: "omit",
        body: formData
    };
    formData.append("username",username)
    formData.append("password",password)
    fetch(`${domain}/accounts/login`, requestOptions)
    .then(res => {
        if(!res.ok){
            throw Error(res.statusText)
        }
        return res.json()
    })
    .then(data =>{
        console.log(data)
        if(data['token']){
            errors.current.textContent = "login successfull, redirecting ..."
            errors.current.className = "text-success fw-bolder"
            setLogin(false)
            setUsername('')
            setPassword('')
            setIsLoggedIn(true);
            userInfo.id = data['user_info'].id
            userInfo.name = data['user_info'].fullname
            userInfo.date = data['user_info'].date_joined
            userInfo.token = data['token']
            setUserInfo({ ...userInfo});
            navigate('/account');
        }
        if(data['non_field_errors']){
          errors.current.textContent = data['non_field_errors'][0]
          errors.current.className = "text-danger fw-bolder"
          setLogin(false)
        }
        
    })
    .catch(err =>{
        console.log(err.message)
    })
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setPassword('');
    userInfo.id = null
    userInfo.name = null
    userInfo.date = null
    userInfo.token = null
    setUserInfo({ ...userInfo});
  };

  const flexed = {
    display:"flex",
    justifyContent:"center",
    gap: 10,
    padding: 15
  }

  const isLoginFormValid = username.trim() !== '' && password.trim() !== '';

  return (
    <div className="login-container">
      {spinner ? <Loader /> : 
        <div className='form'>
            <img className='w-25 m-auto d-block' src={logo} alt='logo'/>
            <h1 className='fs-5 pt-2 pb-2 text-center'>Sign into AskReceiveGive</h1>
            <p ref={errors}></p>
            <input
              type="text"
              className='form-control mb-3'
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
            />
            <input
              type="password"
              className='form-control mb-3'
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
            {login ? 
              <button onClick={handleLogin} className='btn btn-success' disabled>Signing you in</button>
              :
              <button onClick={handleLogin} className='btn btn-success' disabled={!isLoginFormValid}>Login</button>
            }
            <Link to = {'/forgot-password'} className='d-flex justify-content-end pt-2 text-danger'>Forgot password ?</Link>
            <div style={flexed} className='div-new'>
                <p className='m-0'>New to AskReceiveGive</p>
                <Link to={'/register'}>Create an Account</Link>
            </div>
            <hr />
            <div style={flexed} className='links'>
                <Link>Terms & conditons</Link>
                <Link>Privacy Policy</Link>
                <Link>Security</Link>
            </div>
        </div>
        }
    </div>
  );
};

export default Login;
