import React, { useState } from 'react';
import { domain } from './Domain';
import "../css/Donate.css"

const PledgeForm = ({id, setShowAlert, setDonateMessage, donateMessage}) => {
    const [sliderValue, setSliderValue] = useState(0);
    const [addPledge, setAddPledge] = useState(true);

    const handlePledge = (event) => {
        event.preventDefault();
        setAddPledge(false);
        donateMessage.message = ""
        const formData = new FormData(event.target);
        const tip_percentage = formData.get("tip_percentage");
        if(tip_percentage !== 0){
            const tip_amount = (tip_percentage/100)*formData.get("amount");
            const amount = formData.get("amount") - tip_amount;
            formData.append("tip",amount);
        }
        formData.append("fund_request",id);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/pledges`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data)
            event.target.reset();
            setAddPledge(true);
            setShowAlert(true);
            donateMessage.message = "Thank you for pledging. We look forward to hearing from you."
            donateMessage.share = true
            setDonateMessage({...donateMessage})
        })
        .catch(err =>{
            console.log(err.message);
        })
    }

    const handleSliderChange = (event) => {
        setSliderValue(event.target.value);
    };
  return (
    <div id="paymentForm">
        <form action="" className="mt-3" onSubmit={handlePledge}>
            <label for="uname"><b>Names</b></label>
            <input type="text" className="form-control" placeholder="Enter your fullname" name="name" required/>
            <label for="amount"><b>Amount Pledged</b></label>
            <input type="number" className="form-control" placeholder="Enter amount your pledging in ugx" name="amount" required/>
            <label for="psw"><b>Contact for Reminder</b></label>
            <input type="tel" id="telephone" className="form-control" placeholder="Enter Mobile phone number" name="contact" required/>
            <label for="tip"><b>Tip AskReceiveGive Services</b></label>
            <input 
                type="range" 
                min="0" 
                max="30" 
                value={sliderValue}
                onChange={handleSliderChange}
                className="slider"
                name="tip_percentage"/>
            <small>You are tipping us {sliderValue}% of the total donation!</small><br />
            <small className="text-info fw-bold d-block">By tipping us, you enable us continue supporting the fund online platforms </small>
            <label for="date of reminder"><b>Date to be Reminded</b></label>
            <input type="date" className="form-control w-50" name="reminder_date" required/>
            <textarea name="message" className="p-2" rows="4" placeholder="Any Special Message to attach"></textarea>
            {!addPledge && <button type="submit" className="form-control btn mt-2" disabled  >Submiting Pledge ...</button>}
            {addPledge && <button type="submit" className="form-control btn mt-2">Make Pledge Now</button>}
        </form>
    </div>
  )
}

export default PledgeForm