import React, { useState } from 'react';
import "../css/NetworkImage.css"

const NetworkImage = ({ src, alt, height, width }) => {
  const [loading, setLoading] = useState(true);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className="network-image" style={{height: height, width: width}}>
      {loading && <div className="loading-overlay">Loading...</div>}
      <img src={src} alt={alt} onLoad={handleLoad} style={{ display: loading ? 'none' : 'block' }} />
    </div>
  );
};

export default NetworkImage;