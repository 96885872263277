import React, {useState, useEffect, useRef} from 'react'
import { useLoginContext } from './LoginContext';
import "../css/Account.css"
import Header from './Header';
import Footer from './Footer';
import UserInfo from './UserInfo';
import FundraiserUser from './FundraiserUser';
import VolunteerDownloads from './VolunteerDownloads';
import CashRequest from './CashRequest';
import Certificate from './Certificate';
import ChangePassword from './ChangePassword';
import { domain } from './Domain';
import { Link } from 'react-router-dom';



const AccountNav = ({setPage, auth}) => {
    const [isOpen, setIsOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const {isLoggedIn, setIsLoggedIn, userInfo, setUserInfo} = useLoginContext();
    const handleLogout = () => {
        setIsLoggedIn(false);
        userInfo.id = null
        userInfo.name = null
        userInfo.date = null
        userInfo.token = null
        setUserInfo({ ...userInfo});
    };

    const handlePage = (e) => {
        setPage(e)
        setIsOpen(false)
    }
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Function to check the viewport width and update the state
    const checkIsMobile = () => {
        const mediaQuery = window.matchMedia('(max-width: 998px)'); // Define your media query here
        setIsMobile(mediaQuery.matches);
        return mediaQuery.matches
    };
    const handleWindowResize = () => {
        console.log(window.innerWidth);
        console.log(isMobile)
        if(window.innerWidth <= 998){
            setIsOpen(false)
        }else{
            setIsOpen(true)
        }
    };

    // Use useEffect to run the checkIsMobile function when the component mounts and when the viewport size changes
    useEffect(() => {

        window.addEventListener('resize', checkIsMobile);
        //window.addEventListener('resize', handleWindowResize);
        // Clean up the event listener when the component unmounts

        checkIsMobile();
        if(checkIsMobile() === true){
            setIsOpen(false)
        }
        return () => {
            window.removeEventListener('resize', checkIsMobile);
            //window.addEventListener('resize', handleWindowResize);
        };
    }, []);
    
    return(
        <div className='w-100'>
            <Link onClick={toggleMenu} className="menu-button">Account Menu</Link>
            <div className={`menu ${isOpen ? 'sideNavigation open' : 'sideNavigation'}`}>
                <div className="text-center pb-4">
                    <img src="http://media.askreceivegive.com/media/profilepics/bg1.jpg"  className="rounded-circle m-auto" style={{width: 130, height:130}}/>
                    <figcaption>{auth.userInfo.name}</figcaption>
                    <small className="text-muted">Joined on {new Date(auth.userInfo.date).toDateString()}</small>
                </div>
                <ul className='w-100'>
                    <li onClick={() => handlePage('user-info')}>
                        <span><i className="far fa-edit"></i></span>
                        <span>Personnel Information</span>
                    </li>
                    <li onClick={() => handlePage('fundraisers')}>
                        <span><i className="far fa-edit"></i></span>
                        <span>Fundraisers</span>
                    </li>
                    <li onClick={() => handlePage('cash-requests')}>
                        <span><i className="far fa-edit"></i></span>
                        <span>Cashout Requests</span>
                    </li>
                    <li onClick={() => handlePage('volunteer-download')}>
                        <span><i className="far fa-edit"></i></span>
                        <span>Volunteer Downloads</span>
                    </li>
                    <li onClick={() => handlePage('certificates')}>
                        <span><i className="far fa-edit"></i></span>
                        <span>My Certificates</span>
                    </li>
                    <li onClick={() => handlePage('password-change')}>
                        <span><i className="far fa-edit"></i></span>
                        <span>Change Password</span>
                    </li>
                    <li onClick={handleLogout}>
                        <span><i className="far fa-edit"></i></span>
                        <span>Logout</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const Account = () => {
    const auth = useLoginContext()
    const [page, setPage] = useState('user-info')
    
    const divRef = useRef(null);

    // Function to scroll to the start of the div
    const scrollToStart = () => {
        // Use the current property of the ref to access the DOM element
        if (divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    scrollToStart()

    return (
        <div ref={divRef}>
            <Header />
            <div className="mainDiv">
                <AccountNav setPage={setPage} auth={auth}/>
                <div className='w-100 p-4'>
                    <p className='d-none'>This is the value: {JSON.stringify(auth.userInfo)}</p>
                    {page === 'user-info' && <UserInfo />}
                    {page === 'fundraisers' && <FundraiserUser id={auth.userInfo.id}/>}
                    {page === 'volunteer-download' && <VolunteerDownloads id={auth.userInfo.id}/>}
                    {page === 'cash-requests' && <CashRequest id={auth.userInfo.id}/>}
                    {page === 'certificates' && <Certificate id={auth.userInfo.id}/>}
                    {page === 'password-change' && <ChangePassword id={auth.userInfo.id}/>}
                </div>
            </div>
            <Footer />
        </div>
  )
}

export default Account