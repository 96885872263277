import React, {useEffect, useState, useRef} from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from './Header'
import Fundraiser from './Fundraiser'
import Footer from './Footer'
import "../css/SingleCategory.css"
import { useLocation } from 'react-router-dom'
import useFetch from './UseFetch'
import { domain } from './Domain'
import Loader from './Loader'
import DynamicMetaTags from './DynamicMetaTags'
import "../css/Feedback.css"

const modules = {
    toolbar: [
        [{header: [1,2,3,4,5,6, false]}],
        [{font: []}],
        [{size: []}],
        [{color: []}],
        [{background: []}],
        ['bold','italic','underline','strike','blockquote'],
        [
            {"list": "ordered"},
            {"list": "bullet"},
            {"indent": "-1"},
            {"indent": "+1"}
        ],
        ["link","image","video"],
        ["clean"],
    ]
}

const Feedback = () => {
    const [ spinner, setSpinner ] = useState(true);
    const [value, setValue] = useState('');
    const divRef = useRef(null);
    const { state } = useLocation();
    const imageUrl = "/images/logo.png"
    const pageUrl = "https://askreceivegive.com/search-askgive"
    const [response, setResponse] = useState(null)

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500)
    }, []);

    const handleSubmit = (event) =>{
        event.preventDefault()
        if(value === ''){
            alert("please specify the feedback message")
            return
        }
        const formData = new FormData(event.target);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            headers: {
            },
            body: formData
        };
        formData.append("feedback",value)
        fetch(`${domain}/feedback`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(data =>{
            console.log(data)
            event.target.reset()
            setValue(()=>'')
            setResponse(()=>"Response Submitted Successfully")
            setTimeout(function(){
                setResponse(()=>null)
            },5000)
        })
        .catch(err =>{
            console.log(err.message)
        })
    }

    return (
        <>
        {spinner ? <Loader /> : 
            <div ref={divRef}>
                <div>
                    <DynamicMetaTags 
                        pageTitle = {`Feedback | AskReceiveGive`} 
                        metaDescription = {`We appreciate customer feedback and response, feel free to submit whats on your mind about our services`} 
                        imageUrl={imageUrl} 
                        pageUrl = {pageUrl}
                    />
                    <Header />
                    <div className='Heading'>
                        <h1 className='fs-4 pt-2 pb-2 fw-bold'>We would Love to Hear from you to enable us server you better</h1>
                        <form className='MessageForm' onSubmit={handleSubmit}>
                            {response && <p className='text-success fw-bolder'>{response}</p>}
                            <label>Name</label>
                            <input type='text' name='name' placeholder='message title' required/>
                            <label>Email | Phone Number</label>
                            <input type='text' name='contact' placeholder='select a receipient' required />
                            <label>Message</label>
                            <ReactQuill 
                                theme="snow" 
                                value={value} 
                                onChange={setValue}
                                className='editor-input'
                                modules = {modules} />
                            <button className='btn btn-success mt-2'>Send Feedback</button>
                        </form>
                    </div>
                    <Footer />
                </div>
            </div>
        }
        </>   
    )
}

export default Feedback