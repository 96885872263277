import React, {useState} from 'react'
import "../css/Fundraiser.css"
import { Link } from 'react-router-dom';
import { domain } from './Domain';
import truncateString from './TruncateString';

export const ProgressBar = ({ progress }) => {
    return (
      <div className="progress-bar mb-3">
        <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
      </div>
    );
};

const Fundraiser = ({data}) => {
    const [isLoading, setIsLoading] = useState(true);
    const handleImageLoad = () => {
        setIsLoading(false);
    };
    
    return (
        <div className='position-relative card'>
            {isLoading && <div className='position-relative w-100' style={{minHeight: "230px"}}><div className="loading-spinner"></div></div>}
            <img
                src={domain+data.returned_pic}
                alt={data.alt}
                className={`slider-image ${isLoading ? 'hidden' : ''}`}
                onLoad={handleImageLoad}
            />
            <div className='contents'>
                <figcaption>{data.organiser}</figcaption>
                <Link className='text-dark fw-bold' to={`/fundraiser/${data.id}`}><h4>{data.tagline}</h4></Link>
                <Link to={`/fundraiser/${data.id}`} className='text-dark'><p>{truncateString(data.details, 100)}</p></Link>
                {data.last_donation === 0.0 ? 
                    <small className="text-muted">No donations have been made yet</small>
                    :
                    <small className="text-muted">Last Donation {data.last_donation.toLocaleString()}</small>
                }
                
                <ProgressBar progress={data.progress}/>
                <h6><b>{data.total_collected.toLocaleString()} Ugx raised</b> of {data.target_amount.toLocaleString()} Ugx <br /> <span className='d-flex justify-content-end mt-2 text-danger'>{data.infinite}</span></h6>
            </div>
        </div>
    )
}

export default Fundraiser
/*
{% if x.last_donation == None %}
<small class="text-muted">No donations have been made yet</small><br />
{% else %}
<small class="text-muted">Last Donation {{x.last_donation.created}}</small><br />
{% endif %}
*/