import React from 'react';
import '../css/Footer.css'; // Import your CSS file for styling

const Footer = () => {
  return (
   <>
    <footer className="Footer">
      <div className="FooterSection">
        <h3>About AskReceiveGive</h3>
        <p>"AskReceiveGive is a Crowdfunding and Volunteering platform based in Uganda The platform helps well wishes give donations or volunteer to a fundraising campaign of thier own."</p>
      </div>
      <div className="FooterSection">
        <h3>Fundraise For</h3>
        <p>Education</p>
        <p>Charity</p>
        <p>Legal</p>
        <p>Emergency</p>
        <p>Education</p>
      </div>
      <div className="FooterSection">
        <h3>Learn More</h3>
        <p>How it works</p>
        <p>Why AskReceiveGive</p>
        <p>Success Stories</p>
        <p>Common Questions</p>
      </div>
      <div className="FooterSection">
        <h3>Contact Us</h3>
        <p>Email: info@askrecievegive.com</p>
        <p>Phone: 0200-905932</p>
      </div>
    </footer>
    <div className='last'>
        <div>
          <p>Copyright &copy; AskReceiveGive</p>
          <p >Privacy and Cookie Notice</p>
          <p>Terms and Conditions</p>
        </div>
        <p>Powered by Graphite IT</p>
    </div>
   </>
  );
};

export default Footer;
