import React from 'react';
import { Helmet } from 'react-helmet-async';

const DynamicMetaTags = ({pageTitle, metaDescription, imageUrl, pageUrl}) => {
  return (
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={metaDescription} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
  );
};

export default DynamicMetaTags;