
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Entry from './components/Entry';
import Login from './components/Login';
import SingleCategory from './components/SingleCategory';
import Details from './components/Details';
import StartFundraiser from './components/StartFundraiser';
import Register from './components/Register';
import { ForgotPassword } from './components/ForgotPassword';
import PasswordResetForm from './components/PasswordResetForms';
import Account from './components/Account';
import { useLoginContext } from './components/LoginContext';
import ProtectedRoute from './components/ProtectRoute';
import Search from './components/Search';
import Feedback from './components/Feedback';

function App() {
  const auth = useLoginContext();
  //console.log(auth.loggedIn)

  
  return (
    <div className="App">
        <Routes>
          <Route path='/' element={<Entry />}/>
          <Route path='/feedback' element={<Feedback />}/>
          <Route path="/:name" element={<SingleCategory />}></Route>
          <Route path="/fundraiser/:name" element={<Details />}></Route>
          <Route element={<ProtectedRoute />}>
            <Route path='/account' element={<Account />}/>
            <Route path='/start-fundraiser' element={<StartFundraiser />} />
          </Route>
          <Route path='/search-askgive' element={<Search />}/>
          <Route path='/account' element={<Account />}/>
          <Route path='/login' element={<Login />}/>
          <Route path='/register' element={<Register />}/>
          <Route path='/forgot-password' element={<ForgotPassword />}/>
          <Route path="/password-reset/:slug" element={<PasswordResetForm />}></Route>
        </Routes>
    </div>
  );
}

export default App;
