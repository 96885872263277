import React, {useState} from 'react'
import '../css/Report.css'
import { domain } from './Domain'

const Report = ({id}) => {
    const [report, setReport] = useState(true)

    const reportIncorrect = (event) => {
        event.preventDefault();
        setReport(false);
        const formData = new FormData(event.target);
        formData.append("fund_request",id);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/report`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data)
            event.target.reset();
            setReport(true);
        })
        .catch(err =>{
            console.log(err.message);
        })
    }
    return (
        <div className="report">
            <form action="." method="post" onSubmit={reportIncorrect}>
                <select name="reason" className='w-50'>
                    <option value="False information">False information</option>
                    <option value="Fraud">Fraud</option>
                    <option value="History of theft ">History of theft</option>
                    <option value="History of failure to account for funds ">History of failure to account for funds</option>
                    <option value="poor volunteering conditions">poor volunteering conditions</option>
                    <option value="Human trafficing ">Human trafficing </option>
                </select>
                <textarea name="details" rows="4" placeholder="provide more details here" required></textarea>
                <input type="text" required placeholder="phone number" name="contact"/>
                <input type="email" name="email" placeholder="Email Address"/>
                {!report && <button className='btn btn-success'>Submiting Response ... </button>}
                {report && <button className='btn btn-success'>Submit Response</button>}
            </form>
        </div>
    )
}

export default Report