import React, {useEffect, useState} from 'react'
import { useLoginContext } from './LoginContext';
import Loader from './Loader'
import Header from './Header'
import Footer from './Footer'
import "../css/StartFundraiser.css"
import { domain } from './Domain'
import DynamicMetaTags from './DynamicMetaTags';

const StartFundraiser = () => {
    const auth = useLoginContext()
    const [ spinner, setSpinner ] = useState(true);
    const [volunteer, setVolunteer] = useState()
    const [volunteering, setVolunteering] = useState('Volunteering Not Allowed')
    const [submit, setSubmit] = useState(false)
    const handleCheckBox = (e) => {
        setVolunteer(e.target.checked);
        if(e.target.checked === true){
            setVolunteering("Volunteering Allowed")
        }else{
            setVolunteering("Volunteering Not Allowed")
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmit(true);
        const formData = new FormData(event.target);
        //formData.append("fund_request",id);
        let headers = {}
        if(auth.userInfo.token !== null){
            headers.Authorization = `Token ${auth.userInfo.token}`
        }
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData,
            headers
        };
        fetch(`${domain}/add-fundraisers`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data)
            event.target.reset();
            setSubmit(false);
            alert("Submission Successfull!")
        })
        .catch(err =>{
            console.log(err.message);
        })
    }
    useEffect(() => {
        setTimeout(() => setSpinner(false), 500)
    }, []);

    const pageTitle = "Homepage | Start Fundraiser"
    const metaDescription = "To start a fundraiser, please fill the form below and submit it"
    const imageUrl = "/images/logo.png"
    const pageUrl = "https://askreceivegive.com/account"
  return (
    <>
    {spinner ? <Loader /> : 
        <div>
            <DynamicMetaTags 
                pageTitle = {pageTitle} 
                metaDescription = {metaDescription} 
                imageUrl={imageUrl} 
                pageUrl = {pageUrl}
            />
            <Header />
            <div className='startDiv bg-info'>
                <div id="form_div">
                    <div id="replaced">Start Fundraising Request Form</div>
                    <div>
                        <form action="." onSubmit={handleSubmit} class="requestsForm" enctype="multipart/form-data">
                            <h6>{volunteering}</h6>
                            <div class="form-check form-switch">
                                <input onChange={handleCheckBox} class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" />
                            </div>
                            <div>
                                <div id="divided">
                                    <select name="name" class="forms">
                                        <option value="education">Education</option>
                                        <option value="medical">Medical</option>
                                        <option value="friends-and-family">Friends & Family</option>
                                        <option value="charity">Charity</option>
                                        <option value="legal">Legal</option>
                                        <option value="memorial">Memorial</option>
                                    </select>
                                    <input type="text" name="tagline" class="forms" placeholder="tagline" required/>
                                </div>
                                <textarea name="details" cols="30" rows="5" class="forms" placeholder="Details about your request for fundraising"></textarea>
                                <div id="divided">
                                    <textarea name="organiser" required cols="30" rows="2" class="forms" placeholder="Organiser"></textarea>
                                    <textarea name="benefitiary" required cols="30" rows="2" class="forms" placeholder="Benefitiary"></textarea>
                                </div>
                                <div id="divided">
                                    <input type="number" name="target_amount" placeholder="Target Amount Required" class="forms"/>
                                    <input type="tel" name="contact" placeholder="Contact for money deposit" class="forms"/>
                                </div>
                                <textarea name="account_details" cols="30" rows="3" class="forms" placeholder="Please specify your full bank account details incase of bank deposit"></textarea>
                                <div id="divided">
                                    <select name="duration_category" class="forms" onchange="durations(this);">
                                        <option value="days">Days</option>
                                        <option value="months">Months</option>
                                        <option value="years">Years</option>
                                        <option value="infinite">Infinite</option>
                                    </select>
                                    <input type="number" id="duration" class="forms" placeholder="fundraiser duration in days" name="fund_duration"/>
                                </div>
                                <small>Maximum of 4 images, minimum of one</small>
                                <input type="file" name="images" required accept="image/*" multiple onchange="countImages(this);" class="forms" style={{marginLeft: "-10px"}}/>
                                <small class="text-danger" id="msg"></small><br />
                            </div>
                            {volunteer && 
                                <div id="volunteer_information_div">
                                    <h4 style={{fontWeight: 900}} className='text-secondary'>Volunteering Information Required</h4>
                                    <textarea name="services_needed" class="forms" rows="5" placeholder="Services needed, specify each on its own line"></textarea>
                                    <div id="divided">
                                        <input type="date" class="forms" name="date_of_event"/>
                                        <input type="time" class="forms" name="time"/>
                                    </div>
                                    <input type="number" class="forms" placeholder="number of people needed" name="number_people_needed"/>
                                    <input type="text" class="forms" placeholder="location" name="location"/>
                                    <input type="text" class="forms" placeholder="estimated work time" name ="work_time"/>
                                    <div id="divided">
                                        <input type="text" class="forms" placeholder="Age range e.g 25 - 30 years" name="age_range"/>
                                        <select class="forms" name="gender">
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Both Male and Female">Both Male and Female</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <input type="text" class="forms" placeholder="Transport available?" name ="transport"/>
                                    <input type="text" class="forms" placeholder="Food available?" name ="food"/>
                                    <input type="text" class="forms" placeholder="Accomodation available?" name ="accomodation"/>
                                </div>
                            }
                            <div class="agreeToTerms">
                                <input type="checkbox" name="agree" required/>
                                <b style={{fontWeight: 400}}><a href="#" class="pl-1"> Agree to our terms and Conditions</a></b><br />
                            </div>
                            <button onclick="fundraiserRequests();">Submit Request</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    }
    </>
  )
}

export default StartFundraiser