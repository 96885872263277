import React, { useState } from 'react';
import "../css/Donate.css"
import { domain } from './Domain';

const Donate = ({id, setShowAlert, setDonateMessage, donateMessage}) => {
    const [tipAmount, setTipAmount] = useState(0);
    const [amount, setAmount] = useState([]);
    const [otherAmount, setOtherAmount] = useState(false)

    const [addDonation, setAddDonation] = useState(true);

    const handleDonate = (event) => {
        event.preventDefault();
        setAddDonation(false);
        setShowAlert(true);//show the request form here
        donateMessage.message = "Please check your phone and approve the transaction, Thank you"
        donateMessage.share = false
        
        const formData = new FormData(event.target);
        formData.append("fund_request",id);
        formData.append("tip",tipAmount);

        if(formData.get("anonymous") === null){
            formData.append("anonymous",false);
        }else{
            formData.set("anonymous",true);
        }
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/donations`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data)
            event.target.reset();
            setAddDonation(true);
            //setShowAlert(true);
            //setRequesting(true);
            donateMessage.message = data['message']
            donateMessage.share = true
            setDonateMessage({...donateMessage})
        })
        .catch(err =>{
            console.log(err.message);
        })
    }

    const handleTips = (e) => {
        if (e.target.value !== ""){
            const divided = e.target.value / 10
            const myArray = []
            for(let i = 1; i <=3; i++){
                myArray.push({
                    "id":i,
                    "amount": divided * i
                })
            }
            setAmount(myArray)
        }else{
            setAmount([])
        }
        
    }
    const handleBackground = (e) =>{
        e.target.style.background = 'red'
        setTimeout(function(){
            e.target.style.background = '#3B71CA'
        },500)
        setTipAmount(() => e.target.getAttribute('name'))
        setOtherAmount(false);
    }
    const handleOtherTip = (e) => {
        setTipAmount(() => e.target.value);
    }
    const toggleOtherAmount = () => {
        setOtherAmount(!otherAmount);
    }
  return (
    <div id="paymentForm">
        <form action="" className="mt-3" onSubmit={handleDonate}>
            <h6 className="fw-bold mt-0">Select Method of Payment</h6>
            <div className='w-50'>
                <select name="payType" className="form-control"> 
                    <option value="Select carrier" selected className="d-none" >Select carrier</option>
                    <option value="MTN MOBILE MONEY">MTN MOBILE MONEY</option>
                    <option value="AIRTEL MONEY">AIRTEL MONEY</option>
                </select>
            </div>
            <label for="uname"><b>Names</b></label>
            <input type="text" className="form-control" placeholder="Enter your fullname" name="name" require/>
            <label for="psw"><b>Mobile Phone Number</b></label>
            <input type="tel" className="form-control" placeholder="Enter Mobile phone number" name="tell" required/>
            <input type="number" className="form-control mt-2" onChange={handleTips} placeholder="Enter amount to give in Ugx" name="amount" required/>
            <label for="tip"><b>Tip AskReceiveGive Services</b></label>
            {amount.length > 0 &&
            <div className='pt-2 pb-2 d-flex gap-2'>
                {amount &&
                    amount.map(amount => {
                        return(
                            <span key={amount.id} onClick={handleBackground} className='btn btn-sm rounded-pill btn-primary' name={amount.amount}>{amount.amount.toLocaleString("en-US", {style:"currency", currency:"UGX"})}</span>
                        )
                    })
                }
                <span onClick={toggleOtherAmount} className='btn btn-sm rounded-pill btn-success'>Other, specify</span>
            </div>
            }
            {otherAmount && <input type="number" className="form-control w-50 mt-2" onChange={handleOtherTip} placeholder="Specify amount to give in Ugx" />}
            <small>You are tipping us {tipAmount.toLocaleString()} of the total donation!</small><br />
            <small className="text-info fw-bold">By tipping us, you enable us continue supporting the fund online platforms </small>
            <textarea name="message" className="p-2" rows="4" placeholder="Message to the recipient"></textarea>
            <input type="checkbox" className="mr-2 mt-3" style={{width: "auto"}} name="anonymous"/><small> Preffer being anonymous</small><br />
            
            {!addDonation && <button type="submit" className="form-control btn mt-2" disabled  >Submiting Donation Information ...</button>}
            {addDonation && <button type="submit" className="form-control btn mt-2">Make Donation Now</button>}
        </form>
    </div>
  )
}

export default Donate