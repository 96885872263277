import React, { useState, useRef, useEffect } from 'react'
import Header from './Header'
import NetworkImage from './NetworkImage'
import Footer from './Footer'
import "../css/Details.css"
import { Link, useParams } from 'react-router-dom'
import Donate from './Donate'
import VolunteerForm from './VolunteerForm'
import PledgeForm from './PledgeForm'
import useFetch from './UseFetch'
import { domain } from './Domain'
import user from "../user.png"
import Share from './Share'
import Report from './Report'
import DynamicMetaTags from './DynamicMetaTags'
import Loader from './Loader'
const actualDomain = window.location.hostname

const Alert = ({icon, status, btnText, otherInfo, setIsFormVisible, setShowAlert, share, id, data}) => {
    const dismisForm = () => {
        setShowAlert(false);
        setIsFormVisible(false);
    }

    async function allPledgesDonations() {
        const response = await fetch(`${domain}/donation-pledges/${id}`);
        const pledges_donations = await response.json();
        
        let message = `*${data.tagline.trim()}*%0a %0a*Greetings, attached is a list of all received pledges and donations on AskRecieveGive Platform* %0a`
        if(pledges_donations['donations'].length > 0){
            message += "%0a*Overall Donations* %0a %0a"
            //populate the pledges
            pledges_donations['donations'].map((pledge, index) => {
                console.log(pledge, index+1);
                message += `${index+1}. ${pledge.name} ${pledge.amount} %0adonated on: ${pledge.date} %0a`
                //console.log(message)
            })
        }
        if(pledges_donations['pledges'].length > 0){
            message += "%0a*Overall Pledges* %0a %0a"
            //populate the pledges
            pledges_donations['pledges'].map((pledge, index) => {
                console.log(pledge, index+1);
                message += `${index+1}. ${pledge.name} ${pledge.amount} %0apledged on: ${pledge.date} %0a`
                //console.log(message)
            })
        }
        message += `%0aFor more information please visit %0ahttps://${actualDomain+'/fundraiser/'+id}%0aThank you`
        window.open('https://wa.me/?text='+message, 'Whatsapp Chat Window', 'width=700 height=500')
        //console.log(pledges_donations);
    }
      
    const handleShareWhatsapp = () => {
        allPledgesDonations();
    }

    return(
        <div className='alert'>
            {icon}
            <p className='fw-bolder fs-4 pt-2'>{status}</p>
            <small className='d-block'>{otherInfo}</small>
            {share === true &&
            <div className='d-flex'>
                <button className='btn btn-primary mt-3 flex-grow-1' onClick={dismisForm}><i className="fa-regular fa-thumbs-up"></i> {btnText}</button>
                {share && <button className='btn btn-success ms-2 mt-3 flex-grow-1' onClick={handleShareWhatsapp}><i className="fa-brands fa-whatsapp"></i> share</button>}
            </div>
            }
        </div>
    )
}

const Donations = ({data}) => {
    return(
        <div className='d-flex mb-3'>
            <NetworkImage src={user} alt={data.alt} height={"40px"} width={"auto"} />
            <div className='ms-4'>
                <h4 style={{fontSize:"0.875rem"}}><span className='fw-bold'>{data.name}</span> donated <span className='fw-bold'>{data.amount.toLocaleString()} ugx</span></h4>
                <p className='m-0' style={{fontSize:"0.875rem", color:"orangered"}}>{data.message}</p>
                <small className='text-muted mb-3' style={{fontSize:"0.775rem"}}>Donated on {new Date(data.created).toLocaleString()}</small>
            </div>
        </div>
    )
}

const Pledges = ({data}) => {
    return(
        <div className='d-flex mb-3'>
            <NetworkImage src={user} alt={data.alt} height={"40px"} width={"auto"} />
            <div className='ms-4'>
                <h4 style={{fontSize:"0.875rem"}}><span className='fw-bold'>{data.name}</span> Pledged <span className='fw-bold'>{data.amount.toLocaleString()} ugx</span></h4>
                <p className='m-0' style={{fontSize:"0.875rem", color:"orangered"}}>{data.message}</p>
                <small className='text-muted mb-3' style={{fontSize:"0.775rem"}}>Donated on {new Date(data.created).toLocaleString()}</small>
            </div>
        </div>
    )
}

const Details = () => {
    const { name } = useParams();
    const [ spinner, setSpinner ] = useState(true);
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/fundraisers/${name}`);
    const {data:images, loading:loadingImages, error:errorImages} = useFetch(`${domain}/fundraiser-images?q=${name}`);
    const {data:donation, loading:loadingDonation, error:errorDonation} = useFetch(`${domain}/donations?q=${name}`);
    const {data:pledges, loading:loadingPledges, error:errorPledges} = useFetch(`${domain}/pledges?q=${name}`);
    const {data:comments, loading:loadingComments, error:errorComments, refresh:refreshComments, setRefresh:setRefreshComments} = useFetch(`${domain}/comments?q=${name}`);
    const [scrolling, setScrolling] = useState(false);
    const [currentView, setCurrentView] = useState({
        "description":true,
        "donations":false,
        "pledges":false
    })
    const [comment, setComment] = useState('')
    const [viewComments, setViewComments] = useState(false)
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [form, setForm] = useState('');
    const [mainImage, setMainImage] = useState(null)
    const [activeImage, setActiveImage] = useState(null);
    const [addComment, setAddComment] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [donateMessage, setDonateMessage] = useState({
        "message":"Please Check your phone to approve the mobile money request.",
        "share":false
    })

    const toggleForm = (e) => {
        if(e === "donate"){
            setForm("donate");
        }else if(e === "pledge"){
            setForm("pledge");
        }else if(e === "volunteer"){
            setForm("volunteer")
        }
        else if(e === "share"){
            setForm("share")
        }else{
            setForm("report")
        }
        setIsFormVisible(!isFormVisible);
        if(isFormVisible === false){
            setShowAlert(false);
        }
    };

    const handleImageChange = (e, imageId) => {
        setMainImage(e)
        setActiveImage(imageId);
    }

    const handleView = (e) => {
        if(e === "description"){
            currentView.description = true
            currentView.donations = false
            currentView.pledges = false
        }else if(e === "donations"){
            currentView.description = false
            currentView.donations = true
            currentView.pledges = false
        }else{
            currentView.description = false
            currentView.donations = false
            currentView.pledges = true
        }
        setCurrentView({...currentView})
    }

    const handleComments = () => {
        setAddComment(false);
        let formData = new FormData();
        formData.append("comment", comment);
        formData.append("fundraiser_id", name);
        formData.append("owner", 1);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/comments`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data)
            setComment("");
            setAddComment(true);
            setRefreshComments(!refreshComments);
        })
        .catch(err =>{
            console.log(err.message);
        })
    }
    useEffect(() => {
        setTimeout(() => setSpinner(false), 500)
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if(window.innerWidth <= 998){
            if (window.scrollY > 10) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        }
    };

    return (
        <>
        {spinner ? <Loader /> : 
            <div>
                {data && images && 
                    <DynamicMetaTags 
                        pageTitle = {'Details | ' + data.tagline}
                        metaDescription = {data.details}
                        imageUrl={domain+images[0].image}
                        pageUrl = {'https://askreceivegive.com/fundraiser/' + name }
                    />
                }
                <Header />
                <div className='row m-auto container-lg'>
                    <div className='mainImage col-lg-7'>
                        <h1 className='fw-bolder text-center fs-4 pt-4 pb-4' style={{color:'var(--grey)'}}><span className='fw-bolder'>Thank you for opting to help {data && data.benefitiary}</span></h1>
                        <div className='coverImage'>
                            {mainImage === null ? 
                                <NetworkImage src={images && domain+images[0].image} alt={"my network image"} height={'50vh'} width={"100%"}/>
                                :
                                <NetworkImage src={mainImage} alt={"my network image"} height={'50vh'} width={"100%"}/>
                            }
                        </div>
                        <div className='d-flex mt-2'>
                            {images && (
                                images.map(image => {
                                    return(
                                        <div key={image.id} onClick={()=>handleImageChange(domain+image.image, `image${image.id}`)} className={activeImage === `image${image.id}` ? 'activeImage otherImages p-2' : 'otherImages p-2'}>
                                            <NetworkImage src={domain+image.image} alt={"my network image"} height={'90px'} width={"100%"}/>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                        <div className='d-flex justify-content-between organiser-benefitiary align-items-center pt-3'>
                            <div>
                                <h3 className='fs-6 fw-bolder'>Organiser</h3>
                                <p className='fw-light'>{data && data.organiser}</p>
                            </div>
                            <div>
                                <h3 className='fs-6 fw-bolder'>benefitiary</h3>
                                <p className='fw-light'>{data && data.benefitiary}</p>
                            </div>
                            <div className='d-flex align-items-end'>
                                <Link className='me-2 btn btn-success' onClick={() => toggleForm('share')}><i className="fas fa-share rounded-circle"></i> Share</Link>
                                <Link className='btn btn-sm btn-danger' onClick={() => toggleForm('report')}><i className="fa fa-flag-checkered" aria-hidden="true"></i> Report</Link>
                            </div>
                        </div>
                        <div className="btn-group mb-2">
                            <Link onClick={() => handleView("description")} className="btn text-white" aria-current="page" style={{background: "var(--grey)"}}>Description</Link>
                            <Link onClick={() => handleView("donations")} className="btn text-white" style={{background: "var(--blue)"}}>Donations</Link>
                            <Link onClick={() => handleView("pledges")} className="btn text-white" style={{background: "orange"}}>Pledges</Link>
                        </div>
                        {currentView.description && <p className='lead fs-6'>{data && data.details}</p>}
                        {currentView.donations &&
                            <div style={{maxHeight:"40vh", overflowY:"auto"}} className='mt-3'>
                            {donation && (
                                donation.map(donation => {
                                    return(
                                        <Donations data={donation}/>
                                    )
                                    
                                })
                            )}
                            </div>
                        }
                        {currentView.pledges && 
                            <div style={{maxHeight:"40vh", overflowY:"auto"}} className='mt-3'>
                            {pledges && (
                                pledges.map(donation => {
                                    return(
                                        <Pledges data={donation}/>
                                    )
                                    
                                })
                            )}
                            </div>
                        }
                        <h2 className='fs-6' style={{cursor:"pointer", color:"red"}} onClick={()=>{setViewComments(!viewComments)}}>View Comments</h2>
                        {viewComments && 
                            <div>
                            <div style={{maxHeight: "200px", overflowY:"auto"}}>
                                {loadingComments && <p>Loading comments ...</p>}
                                {errorComments && <p>{errorComments}</p>}
                                {comments && 
                                    comments.map(comment => {
                                        return(
                                            <div key={comment.id} className='border-bottom mb-3 ps-4 pb-1'>
                                                <p className='fw-light m-0' style={{fontSize:"0.875rem", color:"orangered"}}>{comment.comment}</p>
                                                <small className='text-muted' style={{fontSize: "0.875rem"}}>posted on {new Date(data.created).toLocaleString()}</small>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <textarea className='form-control mb-2' value={comment} onChange={(e) => setComment(e.target.value)} placeholder='submit a comment'></textarea>
                            {!addComment && <button className='btn btn-success' disabled onClick={handleComments}>Adding comment ...</button>}
                            {addComment && <button className='btn btn-success' disabled={comment.trim() === ''} onClick={handleComments}>comment</button>}
                        </div>
                        }
                    </div>
                    <div className='col-lg-5 detailed'>
                        <h1 className='fw-bolder fs-5 pt-4'>{data && data.tagline}</h1>
                        <hr style={{color:"rgba(0,0,0,0.3)"}} />
                        <p className='fw-bolder'>{donation && donation.length} person(s) have made their <span className='text-danger fw-bolder'>donations</span></p>
                        <div className='border ps-2 rounded'>
                            <p className="mt-4 ">Target Amount Required: <span className='fw-bolder fs-6'>{data && data.target_amount.toLocaleString()} Ugx</span></p>
                            <p>Total Amount Collected: <span className='fw-bolder fs-6'>{data && data.total_collected.toLocaleString()} Ugx</span></p>
                            <p style={{color:'#2e15d4'}}>Total Amount Due: <span className='text-danger fs-6'>{data && (data.target_amount - data.total_collected).toLocaleString()} Ugx</span></p>
                        </div>
                        <div className={`d-flex gap-2 align-items-center ${scrolling ? 'sticky' : ''}`}>
                            <button className='mt-4 btn fw-bolder flex-grow-1' onClick={() => toggleForm('donate')}>Donate</button>
                            {data && data.accept_volunteers && <button className='mt-4 btn fw-bolder flex-grow-1' style={{background: "var(--blue)"}} onClick={() => toggleForm('volunteer')}>Volunter</button>}
                            <button className='mt-4 fw-bolder btn flex-grow-1' style={{background: "orange"}} onClick={() => toggleForm('pledge')} >Pledge</button>
                        </div>
                        <hr style={{color:"rgba(0,0,0,0.3)"}}/>
                        <p className='fw-bolder'>Our Magical Donors</p>
                        {donation && 
                            <>
                            {donation.length > 0 && 
                                <div className={`d-flex flex-wrap recentDonations justify-content-start align-items-end`}>
                                    <div className='m-0 w-50'>
                                        <p className='m-0 fw-bolder'><span className='fw-bolder'>{donation[0].name}</span></p>
                                        <p className='m-0'>{donation[0].amount.toLocaleString()} Ugx</p>
                                    </div>
                                    <p className='m-0 fw-bold text-decoration-underline'>First Donation</p>
                                    <hr className='w-100 mt-1'/>

                                    <div className='m-0 w-50'>
                                        <p className='m-0 fw-bolder'><span className='fw-bolder'>{donation.find(data => data.amount === Math.max(...donation.map(data => data.amount))).name}</span></p>
                                        <p className='m-0'>{Math.max(...donation.map(data => data.amount)).toLocaleString()} Ugx</p>
                                    </div>
                                    <p className='m-0 fw-bold text-decoration-underline'>Highest Donation</p>
                                    <hr className='w-100 mt-1'/>

                                    <div className='m-0 w-50'>
                                        <p className='m-0 fw-bolder'><span className='fw-bolder'>{donation[donation.length-1].name}</span></p>
                                        <p className='m-0'>{donation.at(-1).amount.toLocaleString()} Ugx</p>
                                    </div>
                                    <p className='m-0 fw-bold text-decoration-underline'>Last Donation</p>
                                </div>
                            }
                            </>
                        }
                        {data && data.accept_volunteers && 
                            <>
                                <p className='fw-bolder pt-3 pb-1' >Interested in Volunteering, Please View Requirements for Information before proceeding</p>
                                <div className='border p-3 lead rounded bg-light' style={{fontSize: "0.92rem"}}>
                                    <p className='m-0 mb-1'><span style={{fontWeight:600}}>Services needed:</span> {data.services_needed}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Date of Event:</span> {data.date_of_event}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Event Time:</span> {data.time}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Number of people needed:</span> {data.number_people_needed}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Location:</span> {data.location}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Working time:</span> {data.work_time}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Age Range Needed:</span> {data.age_range}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Allowed Gender:</span> {data.gender}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Transport Allowance:</span> {data.transport}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Food Availability:</span> {data.food}</p>
                                    <p className='m-0 mb-1'><span style={{fontWeight: 600}}>Accomodation Availablility:</span> {data.accomodation}</p>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className={`popup ${isFormVisible ? 'active' : ''}`}>
                    {showAlert ? 
                        <Alert 
                            icon={<i className="fa-solid fa-circle-check text-primary fa-3x"></i>}
                            status = {'Success'}
                            btnText={'Continue'}
                            otherInfo={donateMessage.message}
                            setIsFormVisible={setIsFormVisible}
                            setShowAlert = {setShowAlert}
                            id = {data && data.id}
                            share = {donateMessage.share}
                            data = {data && data}/>
                        :
                        <div className="popup-content">
                            <h1 className='fw-bolder fs-4 d-flex justify-content-between align-items-center'>
                                {form === 'donate' && <span>Thanks for opting to Donate</span> }
                                {form === 'pledge' && <span>Thanks for opting to Pledge</span>}
                                {form === 'volunteer' && <span>Fill the Form Below & Submit</span>}
                                {form === 'share' && <span>Help by Sharing</span>}
                                {form === 'report' && <span>We appreciate your responses</span>} <Link className='text-white' onClick={toggleForm}><i className="fa-solid fa-xmark"></i></Link></h1>
                            <div className="popup-content-inner">
                                {form === 'share' && <Share id={name} data = {data}/>}
                                {form === 'report' && <Report id={name}/>}
                                {form === 'donate' && <Donate id={name} setShowAlert = {setShowAlert} setDonateMessage = {setDonateMessage} donateMessage={donateMessage}/> }
                                {form === 'volunteer' && <VolunteerForm id={name} setShowAlert = {setShowAlert} services_needed = {data && data.services_needed} setDonateMessage = {setDonateMessage} donateMessage={donateMessage}/> }
                                {form === 'pledge' && <PledgeForm id={name} setShowAlert = {setShowAlert} setDonateMessage = {setDonateMessage} donateMessage={donateMessage}/> }
                            </div>
                        </div>
                    }
                </div>
                <Footer />
            </div>
        }
        </>
    )
}

export default Details