import React, {useState, useRef} from 'react'
import { domain } from './Domain';
import useFetch from './UseFetch';
import truncateString from './TruncateString';
import { Link } from 'react-router-dom';
import { useLoginContext } from './LoginContext';
import * as XLSX from 'xlsx';

const VolunteerDownloads = ({id}) => {
    const auth = useLoginContext()
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/fundraisers?id=${auth.userInfo.id}`);
    const {data:dataVolDownload, loading: loadingVolDownload, error:errorVolDownload, refresh:refreshVolDownload, setRefresh:setRefreshVolDownload} = useFetch(`${domain}/accounts/volunteer-information?request_owner=${id}`);
    const [volunteerTagline, setVolunteerTagline] = useState()
    const volunteerId = useRef()
    const [refComponent, setRefreshComponent] = useState(false)

    const handleRequest = (e) => {
        volunteerId.current = e
        console.log(data.filter(elem => elem.id === e));
        const filtered = data.filter(elem => elem.id === e)
        setVolunteerTagline(() => filtered[0].tagline)
    }
    const handleDownloadRequest = (e) => {
        let headers = {}
        if(auth.userInfo.token !== null){
            headers.Authorization = `Token ${auth.userInfo.token}`
        }
        fetch(`${domain}/accounts/volunteer-information?download=${e}`,{headers})
        .then(res => res.json())
        .then(jsonData => {
            const worksheet = XLSX.utils.json_to_sheet(jsonData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            XLSX.writeFile(workbook, "DataSheet.xlsx");
        })
    }

    const handleRequestDownload = (event) =>{
        event.preventDefault()
        console.log(auth.userInfo.token)
        const formData = new FormData(event.target);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            headers: {
                'Authorization': `Token ${auth.userInfo.token}`,
            },
            body: formData
        };
        formData.append("owner",volunteerId.current)
        formData.append("request_owner",auth.userInfo.id)
        fetch(`${domain}/accounts/volunteer-information`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(data =>{
            console.log(data)
            setRefreshComponent(!refComponent)
            setRefresh(!refresh)
            setRefreshVolDownload(!refreshVolDownload)
        })
        .catch(err =>{
            console.log(err.message)
        })

    }
    return (
        <>
            <div>
                <div className='d-block d-sm-flex gap-3'>
                    {error && <p>{error}</p>}
                    {loading && <p>Loading data ....</p>}
                    {data &&
                        (
                        data.map(datas => {
                            return(
                            <div key={datas.id} className='mb-2 w-50'>
                                <img src={domain+datas.returned_pic} className='w-100' style={{height: "250px", objectFit:'contain'}}/>
                                <p className='fw-bold'>{datas.tagline}</p>
                                <p>{truncateString(datas.details, 100)}</p>
                                {dataVolDownload.some(item => item.owner === datas.id) === false && <Link className='btn btn-primary me-3' onClick={() => handleRequest(datas.id)} data-bs-toggle="modal" data-bs-target="#exampleModal">Request Download Permission</Link>}
                                {dataVolDownload.some(item => item.owner === datas.id && item.approved === true) === true && <Link className='btn btn-danger' onClick={() => handleDownloadRequest(datas.id)}>Download Volunteer Information</Link>}
                                {dataVolDownload.some(item => item.owner === datas.id && item.approved === false) === true && <Link className='btn btn-secondary me-3'>pending approval, kindly hold on</Link>}
                                <hr />
                            </div>
                            )
                        })
                        )
                    }
                </div>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                    <div class="modal-header bg-body-tertiary ">
                        <h1 class="modal-title fs-5 fw-bold" id="exampleModalLabel">Please Provide Us With The Information Below</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>{volunteerTagline}</p>
                        <form onSubmit={handleRequestDownload}>
                            <p className='m-0'>Provide Your NIN Number</p>
                            <input type='text' className='form-control w-75 mb-2' placeholder='Enter your nin' name='nin' required />
                            <p className='m-0'>Provide a Copy of your National ID</p>
                            <input type='file' name='nin_image' className='mb-2' />
                            <p className='m-0'>Provide a Police Letter</p>
                            <input type='file' name='letter_image' /><br />
                            <button className='btn btn-success mt-3'>Request For Download Permission</button>
                        </form>
                    </div>
                    <div class="modal-footer d-none">
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VolunteerDownloads