import React, { useState, useRef } from 'react'
import { useLoginContext } from './LoginContext';
import { domain } from './Domain';

const ChangePassword = () => {
    const auth = useLoginContext()
    const isPasswordMatch = useRef()
    const isPasswordCheck = useRef()
    const [response, setResponse] = useState('')
    const [password, setPassword] = useState({
        "old_password":"",
        "new_password":"",
        "new_password_confirm":""
    })

    const handlePasswordChange = (event) => {
        event.preventDefault()
        const formData = new FormData(event.target);
        let headers = {}
        if(auth.userInfo.token !== null){
            headers.Authorization = `Token ${auth.userInfo.token}`
        }
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData,
            headers
        };
        fetch(`${domain}/accounts/password-change`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(data =>{
            console.log(data)
            setResponse(data['message'])
            if(data['message'].includes("your password has been changed successfully!")){
                password.old_password = ''
                password.new_password = ''
                password.new_password_confirm = ''
                setPassword({...password})
                isPasswordCheck.current = ''
                isPasswordMatch.current = ""
            }
        })
        .catch(err =>{
            console.log(err.message)
        })
    }

    const handleChange = (name, e) => {
        const value = e.target.value
        if(name === "old"){
            password.old_password = value;
        }
        if(name === "new"){
            password.new_password = value;
        }
        if(name === "confirm"){
            password.new_password_confirm = value;
        }
        isPasswordCheck.current = password.old_password.trim() !== '' && password.new_password.trim() !== '' && password.new_password_confirm.trim() !== '';
        if(password.new_password.trim() !== password.new_password_confirm){
            isPasswordMatch.current = "The Passwords do not match"
            //isPasswordCheck.current = false
        }else{
            isPasswordMatch.current = ""
        }
        setPassword({...password})
    }

    return (
        <div>
            <p>Dear <span className='fw-bold'>{auth.userInfo.name}</span>,We are here to assist you in changing your password securely. Your online security is of utmost importance to us, and we want to ensure that your account remains safe from unauthorized access.Here are some essential tips to consider while changing your password:</p>
            <h3 className='fw-bolder fs-5'>Password Change Form</h3>
            <span className='text-danger fw-bolder'>{response}</span>
            <form onSubmit={handlePasswordChange}>
                <label className='d-block'>Current Password</label>
                <input type='password' className='form-control' value={password.old_password} onChange={(e) => handleChange("old", e)} name="old_password" placeholder='Enter your Old Password' />
                <label className='d-block'>New Password</label>
                <input type='password' className='form-control' value={password.new_password} onChange={(e) => handleChange("new", e)} name="new_password" placeholder='Set Your New Password Here' />
                <label className='d-block'>Confirm New Password</label>
                <input type='password' className='form-control' value={password.new_password_confirm} onChange={(e) => handleChange("confirm", e)} placeholder='Confirm New Password' name="new_password_confirm" />
                <span className='text-danger d-block fw-bolder mt-2' style={{fontSize: "0.875rem"}}>{isPasswordMatch.current}</span>
                <button className='btn btn-success mt-2' disabled={!isPasswordCheck.current}>Change Password</button>
            </form>
            <h4 className='fw-bolder fs-5 mt-4'>Consider This as You Setup A New Password</h4>
            <ul>
                <li>Strong Password: Please create a strong password that includes a combination of uppercase letters, lowercase letters, numbers, and special characters. Avoid using easily guessable information like birthdays or common words.</li>
                <li>Length Matters: Longer passwords are generally more secure. Aim for a password that is at least 12 characters long.</li>
                <li>Unique Password: Avoid using the same password for multiple accounts. Each online service should have its unique password to prevent security breaches.</li>
                <li>Password Manager: Consider using a password manager tool to generate, store, and manage complex passwords. It can help you keep track of your passwords securely.</li>
                <li>Regular Updates: It's a good practice to change your password periodically. Set a reminder to update your password every few months.</li>
            </ul>
        </div>
    )
}

export default ChangePassword