import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import "../css/Main.css"
import ImageSlider from './ImageSlider';
import { domain } from './Domain';

import image1 from '../images/howto.jpeg';

const Main = ({data}) => {
    const images = [image1];
    {data  && (
        data.length > 0 && (
            data.map(elem => {
                images.push(domain+elem.returned_pic)
            })
        )
    )}
    const [currentIndex, setCurrentIndex] = useState(0);
    
    useEffect(()=>{
        const timer = setInterval(() => {
            // Calculate the next index while looping back to the beginning
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
          }, 3000); // Change images every 3 seconds
      
          return () => clearInterval(timer);
    },[])
    return (
        <div className='Main'>
            <h1>The Slogan Here MayB</h1>
            <div className='div1'>
                <img src={images[currentIndex]} alt={`Image ${currentIndex}`} />
            </div>
            <div className='div2'>
                <img src={images[(currentIndex + 1) % images.length]} alt={`Image ${currentIndex + 1}`} />
            </div>
            <div className='right div3'>
                <h2 className='fw-bold'>Ask Receive Give Community</h2>
                <p>We are delighted to offer this a solution platform to all Ugandans</p>
                <Link to = {'/start-fundraiser'} className='btn btn-outline-light'>Start Campaign</Link>
            </div>
        </div>
    )
}

export default Main