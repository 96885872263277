import React, {useState, useEffect} from 'react'
import "../css/Register.css"
import Header from './Header';
import Footer from './Footer';
import { domain } from './Domain';
import Loader from './Loader'

const Register = () => {
    const [register, setRegister] = useState(false);
    const [ spinner, setSpinner ] = useState(true);

    const handleSelectPic = () => {
        const pp = document.getElementById("pInput");
        pp.click();
    }
    const handleRegister = (event) => {
        event.preventDefault();
        setRegister(true);
        const formData = new FormData(event.target);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/accounts/signup`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data)
            if(data['status'] === "success"){
                setRegister(false);
                event.target.reset();
            }
            
        })
        .catch(err =>{
            console.log(err.message);
        })
    }

    const handleLoadPP = () => {
        const event = document.getElementById("pInput");
        const selectedFile = event.files[0];
        const canvas = document.getElementById("canvas");
        //canvas.title = selectedFile.name;
        if (selectedFile && selectedFile.type.startsWith('image/')) {
            canvas.style.backgroundImage = `url(${URL.createObjectURL(selectedFile)})`;
            canvas.style.backgroundColor = "grey";
            canvas.style.backgroundSize = "contain";
        }else{
            alert("please select an Image")
        }
        
    }
    useEffect(() => {
        setTimeout(() => setSpinner(false), 500)
    }, []);

  return (
    <>
        {spinner ? <Loader /> : 
            <div>
                <Header />
                <div className='registration p-4 ms-4'>
                    <p className='fs-4 pt-2 pb-2 fw-bolder'>User Registration Form</p>
                    <form className='d-block d-md-flex gap-4' onSubmit={handleRegister}>
                        <div className="div">
                            <div className='d-block'>
                                <label>Title</label>
                                <select name="title" className='w-50' required>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Miss">Miss</option>
                                </select>
                            </div>
                            <label>Fullname</label>
                            <div className='d-flex gap-2'>
                                <input   type="text" name="first_name" placeholder="First Name" required />
                                <input type="text" name="last_name" placeholder="Last Name" required />
                            </div>
                            <label>Username</label>
                            <input type="text" name="username" placeholder="System username" required />
                            <label>Email</label>
                            <input type="email" name="email" placeholder="Email Account" required />
                        </div>
                        <div className="div">
                            <div className='d-flex gap-2'>
                                <div className='d-block'>
                                    <label>Phone number</label>
                                    <input type="text" name="contact" placeholder="Phone number" required />
                                </div>
                                <div className='d-block flex-grow-1'>
                                    <label>Gender</label>
                                    <select name="gender" required>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <label>Physical Address</label>
                            <input type="text" name="address" required placeholder="Physical address e.g Makerere-Kavule" />
                            <label for="Date of birth">Date of Birth</label>  
                            <input type="date" required name="DOB" />
                            <label for="Password">Password</label>  
                            <div className='d-flex gap-2'>
                                <input type="password" name="password" placeholder="User password" required />
                                <input type="password" name="password2" placeholder="Confirm password" required />
                            </div>
                        </div>
                        <div className="div">
                            <label for="profile picture">Profile Picture</label>
                            <div className='d-flex align-items-center justify-content-start'>
                                <canvas id="canvas"></canvas>
                                <span className="ms-4 mb-2 btns" onClick={handleSelectPic}>Select Profile Picture</span>
                            </div>
                            <input type="file" id="pInput" className="d-none" accept="*/image" onChange={handleLoadPP} name="profilepic"/>
                            <p className='fs-6 fw-light'>Please Provide a valid email address as it shall be used to activate your account!</p>
                            {!register && <button className='btns'>Submit Registration Details</button> }
                            {register && <button className='btns bg-success' disabled>Submiting Registration Details, please wait ...</button> }
                        </div>        
                    </form>
                </div>
                <Footer />
            </div>
        }
    </>
  )
}

export default Register