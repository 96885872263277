import React, {useState, useRef} from 'react'
import { domain } from './Domain';
import useFetch from './UseFetch';
import truncateString from './TruncateString';
import { useLoginContext } from './LoginContext';
import { Link } from 'react-router-dom';
import "../css/Certificate.css"
import html2canvas from 'html2canvas';

const CertificateLayout = ({data, divRef}) => {
    function handlePrintDiv() {
        const divToDownload = divRef.current
        if (divToDownload) {
            html2canvas(divToDownload).then((canvas) => {
              // Convert canvas to image data
              const imgData = canvas.toDataURL('image/png');
      
              // Create a download link
              const link = document.createElement('a');
              link.href = imgData;
              link.download = 'downloaded.png';
              link.click();
            });
        }
    }
    return(
        <div ref={divRef}>
            <div className="certificate mb-3" style={{cursor: "pointer"}} onDoubleClick={handlePrintDiv}>
                <h3>Certificate of Contribution</h3>
                <p className="text-center p">This is to certify that</p>
                <p className="text-center"><span className="underlined">{data.name}</span></p>
                <p className="text-center p">Has contributed towards</p>
                <p className="text-center"><span className="underlined2">{data.tagline}</span><span> Organised by </span><span className="underlined2">{data.organiser}</span></p>
                <p className="text-center fw-bolder">On {new Date(data.created).toDateString()}</p>
                <p className="text-center">Using <span style={{fontWeight: 'bolder'}}><span style={{color: '#67180b'}}>Ask</span><span style={{color: '#06363d'}}>Receive</span><span style={{color: '#67180b'}}>Give</span></span> Services</p>
                <div className="divided">
                    <div>
                        <img src='images/patrick_signature.PNG' alt="signature"/>
                    </div>
                    <div>
                        <p className='pp'>Patrick Albert Ipola</p>
                        <p className='pp'>Director <span style={{fontWeight: 'bolder'}}><span style={{color: '#67180b'}}>Ask</span><span style={{color: '#06363d'}}>Receive</span><span style={{color: '#67180b'}}>Give</span></span></p>
                        <p className='pp'>www.askreceivegive.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
const Certificate = () => {
    const auth = useLoginContext()
    const divRef = useRef()
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/accounts/certificates`);
    return (
        <div>
            {error && <p>{error}</p>}
            {loading && <p>Loading data ....</p>}
            <h2 className='download-heading m-0 text-danger fw-bolder'>Double click on certificate to download</h2>
            {data &&
                (
                data.map(data => {
                    return(
                        <CertificateLayout key={data.id} data={data} divRef = {divRef} />
                    )
                })
                )
            }
        </div>
    )
}

export default Certificate