import React, {useEffect, useState, useRef} from 'react'
import Header from './Header'
import Fundraiser from './Fundraiser'
import Footer from './Footer'
import "../css/SingleCategory.css"
import { useLocation } from 'react-router-dom'
import useFetch from './UseFetch'
import { domain } from './Domain'
import Loader from './Loader'
import DynamicMetaTags from './DynamicMetaTags'


const Search = () => {
    function capitalizeFirstLetter(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const [ spinner, setSpinner ] = useState(true);
    const { state } = useLocation();
    const {data, loading, error} = useFetch(`${domain}/fundraisers?search=${state}`);

    const divRef = useRef(null);

    // Function to scroll to the start of the div
    const scrollToStart = () => {
        // Use the current property of the ref to access the DOM element
        if (divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    scrollToStart()

    useEffect(() => {
        setTimeout(() => setSpinner(false), 500)
    }, []);

    const imageUrl = "/images/logo.png"
    const pageUrl = "https://askreceivegive.com/search-askgive"
    return (
        <>
            {spinner ? <Loader /> : 
                <div ref={divRef}>
                    <div>
                        <DynamicMetaTags 
                            pageTitle = {'Search | ' + state} 
                            metaDescription = {`search page for AskReceiveGive `} 
                            imageUrl={imageUrl} 
                            pageUrl = {pageUrl}
                        />
                        <Header />
                        <div className='Heading'>
                            <h1 className='fs-4 pt-2 pb-2 fw-bold'>Search Results for {capitalizeFirstLetter(state)} Fundraising Requests</h1>
                        </div>
                        <div className='card-elements'>
                            {error && <p>{error}</p>}
                            {loading && <p>Loading data ....</p>}
                            {data &&
                                (
                                data.map(data => {
                                    return(
                                    <Fundraiser key={data.id} data={data}/>
                                    )
                                })
                                )
                            }
                        </div>
                        <Footer />
                    </div>
                </div>
            }
        </>
    )
}

export default Search