import React, { useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { domain } from './Domain';

const PasswordResetForm = () => {
  const { slug } = useParams();
  const [isPending, setIsPending] = useState(false);
  const regRefSuccess = useRef();
  const [errors, setErrors] = useState();
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    regRefSuccess.current.style.display = "block";
    regRefSuccess.current.textContent = "Requesting password reset ..."
    let form = e.target;
    let data = new FormData(form);
    const requestOptions = {
        method: 'POST',
        cors: "cors",
        body: data
    };
    fetch(`${domain}/accounts/password-reset/${slug}`, requestOptions)
    .then((res) => res.json())
    .then((data) => {
        //console.log(data);
        form.reset();
        regRefSuccess.current.textContent = data['message']
        setTimeout(function(){
            regRefSuccess.current.textContent = '';
            regRefSuccess.current.style.display = "none";
            //navigate("/accounts/login")
        },5000);
        setIsPending(false);
    })
    .catch((err) => console.log(err))
  };

  return (
    <form onSubmit={handleSubmit} className='forgotPassword'>
      <h2 className='fs-5 pt-2 lead fw-bold fg text-secondary '>Reset Your Password</h2>
      <p ref={regRefSuccess} className='text-success' style={{display:"none"}}></p>
      <div>
        <label htmlFor="currentCode" className='pt-2' style={{fontSize: "0.875rem"}}>Secret Code Provided:</label>
        <input
          type="number"
          id="currentCode"
          name="code"
          className='form-control'
          placeholder='code sent to your email'
          required
        />
      </div>
      <div>
        <label htmlFor="newPassword" className='pt-2' style={{fontSize: "0.875rem"}}>New Password:</label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          className='form-control'
          placeholder='New password'
          required
        />
      </div>
      <div>
        <label htmlFor="confirmNewPassword" className='pt-2' style={{fontSize: "0.875rem"}}>Confirm New Password:</label>
        <input
          type="password"
          id="confirmNewPassword"
          name="confirmNewPassword"
          className='form-control'
          placeholder='Confirm Password'
          required
        />
        {errors && (
          <p className="error-message">{errors}</p>
        )}
      </div>
      <button type="submit" className='btn btn-danger mt-2'>Change Password</button>
    </form>
  );
};

export default PasswordResetForm;
