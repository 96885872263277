import React, {useState} from 'react'
import Fundraiser from './Fundraiser'
import { domain } from './Domain'
import useFetch from './UseFetch'

const FundraiserUser = ({id}) => {
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/fundraisers?id=${id}`);
    return (
        <div>
            <div className='d-block d-sm-flex flex-wrap gap-2'>
                {error && <p>{error}</p>}
                {loading && <p>Loading data ....</p>}
                {data &&
                    (
                    data.map(data => {
                        return(
                        <div className='flex-item'>
                            <Fundraiser key={data.id} data={data}/>
                        </div>
                        )
                    })
                    )
                }
            </div>
        </div>
    )
}

export default FundraiserUser