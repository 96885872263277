// ProtectedRoute.js
import React from 'react';
import { useLoginContext } from './LoginContext';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    const auth = useLoginContext();
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth.isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}
export default ProtectedRoute;