import React, {useState, useRef} from 'react'
import { domain } from './Domain';
import useFetch from './UseFetch';
import truncateString from './TruncateString';
import { useLoginContext } from './LoginContext';
import { Link } from 'react-router-dom';
import { ProgressBar } from './Fundraiser';
import "../css/CashRequest.css"

const CashRequest = () => {
    const auth = useLoginContext()
    const volunteerId = useRef()
    const collectedAmount = useRef()
    const [nin, setNin] = useState('')
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/fundraisers?id=${auth.userInfo.id}`);
    const [fundData, setFundData] = useState({})
    const [withdrawAmount, setWithDrawAmount] = useState('')
    const handleCashRequest = (e) => {
        console.log("cash out request data!")
        console.log(e)
        setFundData(() => e)
        setWithDrawAmount(() => '')
        setNin(() => '')
        volunteerId.current = e.id
        collectedAmount.current = e.total_collected
    }

    const handleNin = (event) => {
        setNin(() => event.target.value)
    }

    const handleAmount = (e) => {
        console.log(collectedAmount)
        setWithDrawAmount(() => e.target.value)
    }

    const handleCashOutRequest = (event) =>{
        event.preventDefault()
        console.log(auth.userInfo.token)
        const formData = new FormData(event.target);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            headers: {
                'Authorization': `Token ${auth.userInfo.token}`,
            },
            body: formData
        };
        formData.append("owner",volunteerId.current)
        formData.append("request_owner",auth.userInfo.id)
        fetch(`${domain}/accounts/cash-out`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText)
            }
            return res.json()
        })
        .then(data =>{
            console.log(data)
            alert("Your Request Has Been Received!")
            event.target.reset()
            setWithDrawAmount('')
            setNin('')
        })
        .catch(err =>{
            console.log(err.message)
        })

    }
    return (
        <div className='d-block d-sm-flex gap-2 flex-wrap'>
            {error && <p>{error}</p>}
            {loading && <p>Loading data ....</p>}
            {data &&
                (
                data.map(datas => {
                    return(
                    <div key={datas.id} className='mb-2 flex-item'>
                        <img src={domain+datas.returned_pic} className='w-100' style={{height: 200, objectFit: 'cover'}}/>
                        <p className='fw-bold'>{datas.tagline}</p>
                        <p>{truncateString(datas.details, 100)}</p>
                        <ProgressBar progress={datas.progress}/>
                        <h6><b>{datas.total_collected.toLocaleString()} Ugx raised</b> of {datas.target_amount.toLocaleString()} Ugx <br /> <span className='d-flex justify-content-end mt-2 text-danger'>{data.infinite}</span></h6>
                        <Link className='btn btn-success' onClick={() => handleCashRequest(datas)} data-bs-toggle="modal" data-bs-target="#exampleModal">Request Cashout</Link>
                        <hr />
                    </div>
                    )
                })
                )
            }

            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                    <div class="modal-header bg-body-tertiary ">
                        <h1 class="modal-title fs-5 fw-bold" id="exampleModalLabel">{fundData.tagline}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div className='d-flex gap-2'>
                            <div className='w-50'>
                                <img src={domain+fundData.returned_pic} className='img-fluid'/>
                            </div>
                            <div className='flex-grow-1'>
                                <p className='fw-bold'>Target Amount: {fundData.target_amount && fundData.target_amount.toLocaleString()} /=</p>
                                <p className='text-danger fw-bold'>Total Collected: {fundData.total_collected && fundData.total_collected.toLocaleString()} /=</p>
                                <p>{fundData.details && truncateString(fundData.details, 200)}</p>
                                <p>Created on <br />{fundData.created && fundData.created.toLocaleString()}</p>
                            </div>
                        </div>
                        <form onSubmit={handleCashOutRequest}>
                            <h5 className='fw-bold fs-5 pt-2 pb-2 text-danger'>Please provide the Information Below</h5>
                            <p className='m-0'>Provide Your NIN Number</p>
                            <input type='text' value={nin} onChange={handleNin} className='form-control mb-2' placeholder='Enter your nin' name='nin' required />
                            <p className='m-0'>Amount to Cashout</p>
                            <input type='number' value={withdrawAmount} onChange={handleAmount} className='form-control mb-2 w-50' placeholder='amount' name='amount' /> 
                            <p className='m-0'>Provide a Copy of your National ID</p>
                            <input type='file' name='nin_image' className='mb-2' />
                            <p className='m-0'>Provide a Police Letter</p>
                            <input type='file' name='letter_image' /><br />
                            {nin.length > 0 && withdrawAmount <= collectedAmount.current && withdrawAmount !== '' === true ? <button className='btn btn-success mt-3'>Request CashOut</button> : <button className='btn btn-success mt-3' disabled>Request CashOut</button>}
                        </form>
                    </div>
                    <div class="modal-footer d-none">
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CashRequest