import React from 'react'
import "../css/SingleStory.css"
import truncateString from './TruncateString'
import { domain } from './Domain'
import { Link } from 'react-router-dom'

const SingleStory = ({data}) => {
  return (
      <div style={{background:"#06363D"}} className='Stories'>
          <div>
              <h4>Some of Our Stories</h4>
              <h6>{data && data[0].tagline}</h6>
              <p>{data && truncateString(data[0].details, 300)}</p>
              <Link to={`/fundraiser/${data[0].id}`}>View Fund Request</Link>
          </div>
          <div>
              <img className="lazy" src={data && domain+data[0].returned_pic} />
              <figcaption className="text-center text-white font-weight-bold">Author: {data && data[0].name}</figcaption>
          </div>
      </div>

  )
}

export default SingleStory