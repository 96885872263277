import React, {useState, useRef, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logo from '../logo.png';
import "../css/Header.css"
import { useLoginContext } from './LoginContext';

// Component for the search bar
const SearchBar = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('')
    const handleSearch = () => {
        if (search) { 
            navigate(`/search-askgive`, { state: search, replace: true });
        }
    }
    return (
      <div className="search-bar">
        <div className="input-group">
            <span className="input-group-text"><i className="fa-solid fa-magnifying-glass"></i></span>
            <input type="search" onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="search ..." aria-label="search" aria-describedby="basic-addon1"/>
        </div>
        <button className='fw-bold' onClick={handleSearch}>search</button>
      </div>
    );
};

const Navbar = ({isVisible, handleMenu}) => {
    return(
        <div className={`mobile-menu ${isVisible ? 'Navbar visible' : 'Navbar '}`}>
            <Link to={'/'}>Home</Link>
            <Link to={'/education'} onClick={handleMenu}>Education</Link>
            <Link to={'/medical'} onClick={handleMenu}>Medical</Link>
            <Link to={'/friends-and-family'} onClick={handleMenu}>Friends & Family</Link>
            <Link to={'/charity'} onClick={handleMenu}>Charity</Link>
            <Link to={'/legal'} onClick={handleMenu}>Legal</Link>
            <Link to={'/memorial'} onClick={handleMenu}>Memorial</Link>
            <Link to={'/unfunded'} onClick={handleMenu}>Unfunded Yet</Link>
            <Link to={'/volunteer'} onClick={handleMenu}>Volunteer Requests</Link>
            <Link to={'/feedback'} onClick={handleMenu}>Feedback</Link>
        </div>
    )
}

const Header = () => {
    const [search, setSearch] = useState(false)
    const [isMobile, setIsMobile] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const navBarRef = useRef()
    const auth = useLoginContext()
    const handleSearch = () => {
        setSearch(!search)
    }
    // Function to check the viewport width and update the state
    const checkIsMobile = () => {
        const mediaQuery = window.matchMedia('(max-width: 998px)'); // Define your media query here
        setIsMobile(mediaQuery.matches);
    };

    const handleMenu = () => {
        if (isVisible) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }

    // Use useEffect to run the checkIsMobile function when the component mounts and when the viewport size changes
    useEffect(() => {
        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);
        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return (
        <div className='Header'>
            <div className='d-flex justify-content-between align-items-center Inner'>
                <div className='d-flex align-items-center w-25 logo'>
                    <h3 className='me-2 fs-4'><span style={{color: "var(--grey)"}}>Ask</span><span style={{color: "var(--blue)"}}>Receive</span><span style={{color: "var(--grey)"}}>Give</span></h3>
                    <img src={logo} alt='logo'/>
                </div>
                {isMobile === false &&  <SearchBar />}
                {search && <SearchBar />}
                <div className='fundraise'>
                    <Link to = {'/start-fundraiser'} className='btn text-white fw-bold' style={{background: "var(--grey)"}}>start Fundraiser</Link>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='me-3 action search align-items-center' onClick={handleSearch}>
                        <i className="fa-brands fa-searchengin me-2"></i>
                        <span className='fs-6 fw-bolder'>Search</span>
                    </div>
                    {auth.isLoggedIn ? 
                        <Link to = {'/account'} className='fw-bolder text-muted border rounded ps-2 pe-2 btn'>Hi {auth.userInfo.name.split(" ")[0]}</Link> 
                        :
                        <Link className='action d-flex align-items-center text-dark border rounded ps-4 pe-4 btn' to={'/login'}>
                            <i className="fa-solid fa-right-to-bracket me-2"></i>
                            <span className='fs-6 fw-bold'> Login</span>
                        </Link>
                    }
                    <Link onClick={handleMenu} className='ms-3 fs-6 btn text-success border mobileOn'><i className='fa fa-bars'></i></Link>
                </div>
            </div>
            <Navbar isVisible = {isVisible} handleMenu = {handleMenu}/>
        </div>
    )
}

export default Header