import React, {useState, useEffect} from 'react'
import "../css/Volunteer.css"
import { domain } from './Domain';

const VolunteerForm = ({id, setShowAlert, setRequesting, services_needed, setDonateMessage, donateMessage}) => {
    const services = services_needed.split("\n")
    const [addVolunteer, setAddVolunteer] = useState(true);

    const handleVolunteer = (event) => {
        event.preventDefault();
        setAddVolunteer(false);
        donateMessage.message = ""
        
        const formData = new FormData(event.target);
        formData.append("fund_request",id);
        const requestOptions = {
            method: 'POST',
            credentials: "omit",
            body: formData
        };
        fetch(`${domain}/volunteer`, requestOptions)
        .then(res => {
            if(!res.ok){
                throw Error(res.statusText);
            }
            return res.json();
        })
        .then(data =>{
            console.log(data)
            event.target.reset();
            setAddVolunteer(true);
            setShowAlert(true);
            donateMessage.message = "Thank you for submission. The Event Organiser will contact you soon."
            donateMessage.share = true
            setDonateMessage({...donateMessage})
            setRequesting(false);
        })
        .catch(err =>{
            console.log(err.message);
        })
    }
    return (
        <div id="volunteerForm">
            <form action="" onSubmit={handleVolunteer} method="POST">
                <label for="name">Name</label>
                <input type="text" className="form-control" placeholder="Enter your fullname" name="name" required/>
                <div className='divided'>
                    <div>
                        <label for="age">Age</label>
                        <input type="number" required placeholder="Enter your Age" name="age"/>
                    </div>
                    <div>
                        <label for="sex">Gender</label>
                        <select name="sex" required>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="both male and female">both Male and Female</option>
                            <option value="others">Others</option>
                        </select>
                    </div>
                </div>
                <label for="location">Location</label>
                <input type="text" required placeholder="Enter your location" name="location"/>
                <label for="profession">Profession</label>
                <input type="text" required placeholder="Enter your profession" name="profession"/>
                <label for="email">Email</label>
                <input type="email" required placeholder="Enter your email" name="email"/>
                <div className='divided'>
                    <div>
                        <label for="contact1">Contact 1</label>
                        <input type="text" required placeholder="first contact" name="contact1"/>
                    </div>
                    <div>
                        <label for="contact2">Contact 2</label>
                        <input type="text" placeholder="second contact" name="contact2"/>
                    </div>
                </div>
                
                <label for="category of support">Category of support</label><br />
                {services.map(service => {
                    return(
                        <>
                            <input type="checkbox" value={service.trim()} name="category_of_support" style={{width: 'auto'}}/>  <span style={{fontSize: '0.875rem'}}>{service}</span><br />
                        </>
                    )
                })}
                
                <label for="support description">Describe the category you can support</label>
                <textarea name="support_offered" rows="5" placeholder="Describe the category of support that you can offer"></textarea>
                <label for="special information">Special Information</label>
                <textarea name="special_information_to_know" rows="5" placeholder="Any Special Information the organizer should know"></textarea>
                <label for="emergency contact">Name of emergency contact</label>
                <input type="text" placeholder="Name of emergency contact" name="name_of_emergency_contact"/>
                <label for="relationship">Relationship</label>
                <select name="relationship">
                    <option value="mother">Mother</option>
                    <option value="father">Father</option>
                    <option value="friend">Friend</option>
                    <option value="brother">Brother</option>
                    <option value="sister">Sister</option>
                    <option value="uncle">Uncle</option>
                    <option value="aunt">Aunt</option>
                    <option value="dad">Dad</option>
                    <option value="mum">Mum</option>
                    <option value="pastor">Pastor</option>
                    <option value="care taker">Care Taker</option>
                    <option value="others">Others</option>
                </select>
                <label for="conatact email">Contact Email</label>
                <input type="email" placeholder="Emergency contact email" name="emergency_email"/>
                <label for="contact3">Emergency Contact</label>
                <input type="text" placeholder="Emergency contact" name="emergency_contact"/>
                <input type="checkbox" className="mr-2" style={{width: 'auto'}} name="terms" required/><small> Agree to terms and conditions</small><br />
                {!addVolunteer && <button type="submit" className="form-control btn mt-2" disabled  >Submiting Volunteer Information ...</button>}
                {addVolunteer && <button type="submit" className="form-control btn mt-2">Submit Volunteer Form</button>}
            </form>
        </div>
    )
}

export default VolunteerForm