import React, {useState, useEffect} from 'react'
import Header from './Header'
import Main from './Main'
import Stats from './Stats'
import Categories from './Categories'
import Fundraiser from './Fundraiser'
import SingleStory from './SingleStory'
import Footer from './Footer'
import Loader from './Loader'
import useFetch from './UseFetch'
import { Link } from 'react-router-dom'
import { domain } from './Domain'
import DynamicMetaTags from './DynamicMetaTags'

const Entry = () => {
  const [ spinner, setSpinner ] = useState(true);
  const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/fundraisers`);
  const [randomItems, setRandomItems] = useState([]);
  

  const getRandomIndices = (length, count) => {
    const indices = [];
    while (indices.length < count) {
      const randomIndex = Math.floor(Math.random() * length);
      if (!indices.includes(randomIndex)) {
        indices.push(randomIndex);
      }
    }
    return indices;
  };
  useEffect(() => {
    setTimeout(() => setSpinner(false), 500)
  }, []);

  useEffect(() => {
    if(data){
      if (data.length > 0) {
        const randomIndices = getRandomIndices(data.length, 6);
        const selectedRandomElements = randomIndices.map(index => data[index]);
        setRandomItems(selectedRandomElements);
      }
    }
  }, [data]);
  const pageTitle = "Homepage | AskReceiveGive"
  const metaDescription = "AskReceiveGive is a Crowdfunding and Volunteering platform based in Uganda The platform helps well wishes give donations or volunteer to a fundraising campaign of their own"
  const imageUrl = "/images/logo.png"
  const pageUrl = "https://askreceivegive.com/"
  /*
            <DynamicMetaTags 
            pageTitle = {pageTitle} 
            metaDescription = {metaDescription} 
            imageUrl={imageUrl} 
            pageUrl = {pageUrl}
          />
  */
  
  return (
    <>
      {spinner ? <Loader /> : 
        <div>
          <Header />
          <Main data={data} />
          <Stats />
          <Categories />
          <h1 className='text-center fs-4 pt-4 pb-4 fw-bold'>Explore New Campaigns</h1>
          <div className='card-elements'>
            {error && <p>{error}</p>}
            {loading && <p>Loading data ....</p>}
            {data &&
              (
                data.slice(0,3).map(data => {
                  return(
                    <Fundraiser key={data.id} data={data}/>
                  )
                })
              )
            }
          </div>
          <div className='text-center'>
            <Link to={'/all'} className='btn btn-outline-primary mt-4 mb-4'>View All Campaigns</Link>
          </div>
          {data && <SingleStory data={data.slice(6,7)}/>}
          <h1 className='text-center fs-4 pt-4 pb-4 fw-bold'>Top Fundraisers on AskRecieveGive</h1>
          <div className='card-elements'>
            {error && <p>{error}</p>}
            {loading && <p>Loading data ....</p>}
            {data &&
              (
                data.slice(3,6).map(data => {
                  return(
                    <Fundraiser key={data.id} data={data}/>
                  )
                })
              )
            }
          </div>
          <div className='text-center'>
            <Link to={'/all'} className='btn btn-outline-primary mt-4 mb-4'>View All Campaigns</Link>
          </div>
          <Footer />
        </div>
      }
    </>
  )

}

export default Entry