import React, { createContext, useContext, useState } from 'react';

const LoginContext = createContext();

export const useLoginContext = () => {
  return useContext(LoginContext);
};

export const LoginProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({ id: null, token: null, name: null, date: null });
  //console.log(isLoggedIn)
  // Other login-related data and functions can be added here

  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn, userInfo, setUserInfo }}>
      {children}
    </LoginContext.Provider>
  );
};
