import React, {useState, useEffect} from 'react'
import "../css/Stats.css"
import useFetch from './UseFetch'
import { domain } from './Domain'

const Stats = () => {
    const {data:stats, loading, error} = useFetch(`${domain}/stats`);
    
    return (
        <div className='Stats d-flex'>
            {loading && <p>loading ...</p>}
            {error && <p>{error}</p>}
            <div className='text-center'>
                <i className="fa fa-users" aria-hidden="true"></i><br />
                {stats && stats.total_donations.toLocaleString()} people have donated
            </div>
            <div className='text-center'>
                <i className="fa fa-money-bill-wave align-baseline"></i><br />
                {stats && stats.total_donations_amount.toLocaleString()} /= Total Raised
            </div>
            <div className='text-center'>
                <i className="fa fa-bullhorn" aria-hidden="true"></i><br />
                {stats && stats.total_campaigns.toLocaleString()} Fundraising Campaigs
            </div>
            <div className='text-center'>
                <i className="fas fa-dove"></i>
                <i className=" d-none fas fa-hand-holding-heart"></i>
                <i className="d-none fas fa-ribbon"></i><br />
                {stats && stats.total_volunteer_requests.toLocaleString()} Volunteer Requests
            </div>
        </div>
  )
}

export default Stats