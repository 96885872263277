import React from 'react'
import '../css/Categories.css'
import useFetch from './UseFetch'
import { domain } from './Domain'
import { Link } from 'react-router-dom'

const Categories = () => {
    const {data, loading, error, refresh, setRefresh} = useFetch(`${domain}/fundraisers-count`);
    return (
        <div>
            <div className='text-center'>
                <h1 className='fs-5 fw-bolder heading'>Fund by Category</h1>
            </div>
            
            <div id="images">
                {error && <p>{error}</p>}
                {loading && <p>Loading data ....</p>}
                    {data && (
                        data.map(data => {
                        return(
                            <Link to={`/${data.name}`} key={data.name} className='images'>
                                <img src={domain+data.image} alt="logo"/>
                                <div className="over2">
                                    <p className='text-white'>{data.name}</p>
                                </div>
                                <p className='fs-2 count position-absolute d-none'>{data.count}</p>
                                <div className="over"></div>
                            </Link>
                        )
                    })
                )}
            </div>
        </div>
    )
}

export default Categories